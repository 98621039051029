import * as React from "react"
import { Layout, TopNavigation, CenterText } from 'components';


const NotFoundPage = ({ location }) => {
  const seo = {};
  return (
    <Layout location={location} seo={seo}>
      <TopNavigation />
      <CenterText text="404 - PAGE NOT FOUND" />
    </Layout>
  )
}

export default NotFoundPage
